<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Reporting Manager</h1>
        <v-btn class="ml-2" fab color="info" small @click="openAddControllerDialog"><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn class="ml-2" color="warning" small @click="reloadRoutes">Reload Routes</v-btn>
        <v-btn class="ml-2" color="info" small @click="openLogWindow">See Files</v-btn>
        <v-btn v-if="selectedController" class="ml-2" color="secondary" small :to="`/reporting/${selectedController.route}`">View Report</v-btn>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="initialLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field label="Search Reports" v-model="searchControllers" clearable @click:clear="filterControllers" outlined dense @input="filterControllers"/>
        <span>
          <span class="d-flex flex-row align-center mb-2">
            <h3>Routes/Controllers <span v-if="!searchControllers">(drag to reorder)</span></h3>
            <v-btn class="ml-2" color="info" small @click="openAddGroupDialog">New Group</v-btn>
          </span>
          <span v-if="!searchControllers">
            <v-btn block color="success" :loading="orderLoading" class="mb-2" @click="saveControllerOrder">Save Order</v-btn>
            <draggable :list="controllers" v-bind="dragOptions" @start="groupDrag=true" @end="groupOrderChanged">
              <transition-group type="transition" :name="!groupDrag ? 'flip-list' : null">
                <v-card v-for="(group, g) of controllers" :key="g" class="mb-5">
                  <v-card-title class="d-flex flex-row justify-space-between">
                    <span class="d-flex flex-row justify-space-between">
                      <span>{{group.name}}</span>
<!--                      <v-btn x-small color="secondary" @click="openConfigureGroup(group)" outlined text fab class="ml-2"><v-icon>mdi-cog</v-icon></v-btn>-->
                    </span>
                    <span><span class="green--text">{{group.groupOrder}}</span> | {{g}}</span>
                  </v-card-title>
                  <hr>
                  <draggable style="display: block; min-width: 100%; min-height: 25px;" :list="group.controllers" group="controllers" v-bind="dragOptions" @start="drag=true" @end="orderChanged">
                    <transition-group style="display: block; min-width: 100%; min-height: 25px;" type="transition" :name="!drag ? 'flip-list' : null">
                      <v-card tile flat :color="selectedController?.id===controller.id?'blue-grey darken-1':''" v-for="(controller, i) of group.controllers" :key="i" @click="loadController(controller.id)">
                        <v-card-text :class="`elevation-2 pa-2 mb-1 d-flex justify-space-between`">
                          <span class="d-flex flex-column">
                            <b>{{controller.metadata?.label||'Unnamed'}}</b>
                            <span>router.<span class="red--text">{{controller.routeType}}</span>("/<span class="blue--text">{{controller.route}}</span>", table.<span class="orange--text">{{controller.name}}</span>)</span>
                            <span style="font-size: 12px;">{{(controller.contents||"").length}} chars</span>
                          </span>
                          <span><span class="green--text">{{controller.order}}</span> | {{i}}</span>
                        </v-card-text>
                      </v-card>
                    </transition-group>
                  </draggable>
                </v-card>
              </transition-group>
            </draggable>
          </span>

          <span v-if="searchControllers">
            <v-card color="info" v-for="(group, g) of controllers" :key="g" class="mb-5">
              <v-card-title>
                <span>{{group.name}}</span>
                <span><span class="green--text">{{group.groupOrder}}</span> | {{g}}</span>
              </v-card-title>
                <v-card-text :class="` elevation-2 pa-2 mb-1 d-flex justify-space-between`" v-for="(controller, i) of group.controllers" :key="i" @click="loadController(controller.id)">
                  <span class="">
                    <span class="ml-2">router.<span class="red--text">{{controller.routeType}}</span>("/<span class="blue--text">{{controller.route}}</span>", table.<span class="orange--text">{{controller.name}}</span>)</span>
                  </span>
                  <span><span class="green--text">{{controller.order}}</span> | {{i}}</span>
                </v-card-text>
            </v-card>
          </span>
        </span>
      </v-col>
      <v-col cols="8">
        <span v-if="selectedController && selectedController.id" class="d-flex flex-column">
          <span class="d-flex flex-row justify-space-between">
            <span class="d-flex flex-row align-center">
              <v-btn color="success" :disabled="!validControllerValues(selectedController)" :loading="saveLoading" @click="saveController">Save</v-btn>
              <v-select class="ml-2" outlined dense hide-details :items="themes" v-model="cmOptions.theme" label="Text Editor Theme"/>
              <v-btn class="ml-2" color="info" :disabled="!validControllerValues(selectedController)" @click="duplicateController">Create A Copy</v-btn>
            </span>
            <span>
              <v-btn class="mr-1" small color="warning" :loading="testLoading" @click="testController">Test</v-btn>
              <v-btn color="error" :loading="deleteLoading" @click="deleteController">Delete</v-btn>
            </span>
          </span>
          <span class="mt-5 mb-2 d-flex flex-row align-center">
            <v-text-field hide-details outlined dense label="Name" disabled v-model="selectedController.name" class="orange"/>
            <v-text-field hide-details class="info ml-1" outlined dense label="Route" v-model="selectedController.route"/>
            <v-select hide-details class="red ml-1" style="max-width: 100px;" outlined dense :items="routeTypes" v-model="selectedController.routeType" label="Route Type"/>
          </span>
          <span class="d-flex flex-column">
            <span class="mt-5 mb-2 d-flex flex-row">
              <v-select hide-details multiple clearable outlined dense :items="userTypes" item-text="name" item-value="id" label="Allowed To View" v-model="selectedController.metadata.allowedUserTypes"/>
            </span>
            <span class="d-flex flex-row align-center">
              <v-text-field hide-details outlined dense label="Button Label" v-model="selectedController.metadata.label"/>
              <v-text-field hide-details class="mx-1" outlined dense label="Button Icon" v-model="selectedController.metadata.icon"/>
              <v-text-field hide-details outlined dense label="Button Color" v-model="selectedController.metadata.color"/>
              <span class="d-flex flex-row align-center ml-2">
                <b>Hidden?</b>
                <v-checkbox v-model="selectedController.metadata.hidden"/>
              </span>
            </span>
            <span class="d-flex flex-row align-center mt-3">
              <b class="mr-2">Filters ({{selectedController.metadata.allFilters.reduce((a, b) => a+b.filters.length, 0)}})</b>
              <v-btn small color="warning" @click="filtersControl.show=!filtersControl.show">{{filtersControl.show?'Hide':'Show'}} Filters</v-btn>
              <v-btn class="ml-2" small color="warning" @click="filtersControl.isOpen=true">Preview Custom Filters</v-btn>
              <v-btn class="ml-2" color="info" x-small @click="addRow"><v-icon>mdi-plus</v-icon> Row</v-btn>
              <v-btn class="ml-2" x-small color="info" @click="setSampleFilters">Clear and Set Sample Filters</v-btn>
            </span>
            <span v-if="filtersControl.show">
              <b>Default Filters</b>
              <span class="d-flex flex-row flex-wrap">
                <v-checkbox hide-details class="ml-2 mr-2 mt-0" label="By Date" v-model="selectedController.metadata.filterByDate"/>
                <v-switch hide-details class="ml-2 mr-2 mt-0" label="End Date Only?" v-model="selectedController.metadata.endDateOnly"/>
                <v-checkbox hide-details class="ml-2 mr-2 mt-0" label="By User" v-model="selectedController.metadata.filterByUser"/>
                <v-switch hide-details class="ml-2 mr-2 mt-0" label="User Exclusion Too?" v-model="selectedController.metadata.excludeUser"/>
                <v-checkbox hide-details class="ml-2 mr-2 mt-0" label="By Branch" v-model="selectedController.metadata.filterByBranch"/>
                <v-checkbox hide-details class="ml-2 mr-2 mt-0" label="Single Branch" v-model="selectedController.metadata.singleBranch"/>
                <v-checkbox hide-details class="ml-2 mr-2 mt-0" label="By Payment Type" v-model="selectedController.metadata.filterByPaymentType"/>
              </span>
              <v-card class="mt-2" outlined v-for="(row, r) of selectedController.metadata.allFilters" :key="r">
                <v-card-text class="d-flex flex-column">
                  <span>
                    <span class="d-flex flex-row">
                      <v-btn outlined @click="moveUp(r, selectedController.metadata.allFilters)" text x-small class="pa-0"><v-icon>mdi-transfer-up</v-icon></v-btn>
                      <v-btn outlined @click="moveDown(r, selectedController.metadata.allFilters)" text x-small class="pa-0"><v-icon>mdi-transfer-down</v-icon></v-btn>
                      <b class="ml-2">Row {{r+1}}</b>
                      <v-btn class="ml-2" color="info" x-small @click="addFilter(row)"><v-icon>mdi-plus</v-icon> Filter</v-btn>
                      <v-btn class="ml-2" color="error" x-small @click="deleteRow(r)"><v-icon>mdi-close</v-icon> Row</v-btn>
                    </span>
                    <v-card outlined>
                      <v-card-text>
                        <span>
                          <b class="mr-2">Row Attributes</b>
                          <v-btn color="info" x-small @click="openAddAttributeDialog(row)"><v-icon>mdi-plus</v-icon> Attribute</v-btn>
                        </span>
                        <v-card outlined v-for="(attr, a) of Object.keys(row.attributes)" :key="a" class="mt-2">
                          <v-card-text class="d-flex flex-row justify-space-between align-center mb-2">
                            <b style="width: 70px;">{{attr}}</b>
                            <span class="d-flex flex-column flex-fill">
                              <v-text-field class="mb-1" dense outlined v-model="row.attributes[attr].value" hide-details label="Value"/>
                              <v-text-field v-if="attr==='items'" class="mb-1" dense outlined v-model="row.attributes[attr].route" hide-details label="Data Route"/>
                            </span>
                            <v-btn class="ml-2" color="error" x-small fab @click="deleteAttribute(row, attr)"><v-icon>mdi-close</v-icon></v-btn>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </span>
                  <span class="d-flex flex-wrap">
                    <v-card class="mt-1 col-12" outlined v-for="(filter, f) of row.filters" :key="f">
                      <span class="d-flex flex-row justify-space-between">
                        <v-card-title class="d-flex flex-row">
                          <v-btn outlined @click="moveUp(f, row.filters)" text x-small class="pa-0"><v-icon>mdi-transfer-up</v-icon></v-btn>
                          <v-btn outlined @click="moveDown(f, row.filters)" text x-small class="pa-0"><v-icon>mdi-transfer-down</v-icon></v-btn>
                          <b class="ml-2">Row {{r+1}} - Filter {{f+1}}</b>
                          <v-btn class="ml-2" color="error" x-small @click="deleteFilter(row, f)"><v-icon>mdi-close</v-icon> Filter</v-btn>
                        </v-card-title>
                        <v-card class="d-flex flex-row flex-fill">
                          <v-card-text>
                            <h3 class="d-flex flex-row align-center mb-2">
                              <span>Preview</span>
                              <v-btn class="ml-2" x-small fab color="warning" @click="filtersControl.isOpen=true"><v-icon>mdi-eye</v-icon></v-btn>
                              <v-btn class="ml-2" x-small fab color="info" @click="refreshPreview(filter)"><v-icon>mdi-refresh</v-icon></v-btn>
                            </h3>
                            <template>
                              <v-menu
                                  v-if="filter.inputType==='date'"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :label="filter.label"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      outlined
                                      v-bind="parseAttributes(filter.attributes)"
                                      hide-details
                                      v-on="on"
                                  />
                                </template>
                                <v-date-picker/>
                              </v-menu>
                              <component v-else :is="filter.inputType" @click="filter.attributes?.content?handleEval(filter.attributes.content.mapper):null" :items="filter.attributes?.items?parsedDataAttributes[filter.attributes.items.value]:null" v-bind="parseAttributes(filter.attributes)" :label="filter.label"><span v-html="filter.attributes.content?.value"></span></component>
                            </template>
                          </v-card-text>
                        </v-card>
                      </span>
                      <v-card-text>
                        <span class="d-flex flex-row">
                          <v-text-field dense outlined v-model="filter.label" label="Label" class="mr-2"/>
                          <v-text-field dense outlined v-model="filter.inputType" label="Input Type" class="mr-2" append-icon="mdi-information-variant-circle" @click:append="openInputTypeDialog(filter)"/>
                          <v-text-field dense outlined v-model="filter.filtersCustomKey" label="Reporting Custom Key"/>
                        </span>
                        <span>
                          <b class="mr-2">Attributes</b>
                          <v-btn color="info" x-small @click="openAddAttributeDialog(filter)"><v-icon>mdi-plus</v-icon> Attribute</v-btn>
                        </span>
                        <v-card outlined v-for="(attr, a) of Object.keys(filter.attributes)" :key="a" class="mt-2">
                          <v-card-text class="d-flex flex-row justify-space-between align-center mb-2">
                            <b style="width: 70px;">{{attr}}</b>
                            <span class="d-flex flex-column flex-fill">
                              <v-text-field class="mb-1" dense outlined v-model="filter.attributes[attr].value" hide-details label="Value"/>
                              <v-text-field spellcheck="false" v-if="attr==='items'" class="mb-1" dense outlined v-model="filter.attributes[attr].route" hint="eval | `${this.getEndpoint}/api/users`" persistent-hint label="Data Route"/>
                              <span v-if="attr==='items' || attr==='content'">
                                <b>Mapper For items(use data->return {text,value}) / Function for @click content (JS)</b>
                                <codemirror class="CodeMirrorHeightAuto mb-1" :options="{...cmOptions, firstLineNumber: 1}" v-model="filter.attributes[attr].mapper"/>
                                <span>eval | data.map((x, i) => {return {text: x.name, value: x.id, item: x, index: i}}) | if the same 'Value' exists on another filter and has this field, no need to include it here again</span>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <v-btn class="ml-2" color="error" x-small fab @click="deleteAttribute(filter, attr)"><v-icon>mdi-close</v-icon></v-btn>
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-card-text>
              </v-card>
            </span>
          </span>
          <hr>
          <span class="my-2">
            <span class="d-flex flex-row align-center mb-2">
              <v-btn small color="info" @click="importsGlobal.show=!importsGlobal.show">{{!importsGlobal.show?"Show":"Hide"}} Imports</v-btn>
              <v-btn v-if="importsGlobal.show" class="ml-2" @click="saveImports" color="success" :loading="importsGlobal.loading">Save Imports</v-btn>
            </span>
            <span v-if="importsGlobal.show">
              <codemirror class="CodeMirrorHeightAuto" :options="cmOptions" v-model="importsGlobal.data"/>
            </span>
          </span>
          <hr>
          <span class="my-2">
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, theme: 'dracula', viewportMargin: Infinity, readOnly: true}" :value="selectedControllerPrefix"/>
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, firstLineNumber: 2}" v-model="selectedController.contents"/>
            <codemirror class="CodeMirrorHeightAuto" :options="{...cmOptions, firstLineNumber:  (selectedController.contents||'').split(/\r\n|\r|\n/).length+2, theme: 'dracula', viewportMargin: Infinity, readOnly: true}" :value="selectedControllerSuffix"/>
          </span>
        </span>
        <span v-if="selectedController && !selectedController.id" class="d-flex flex-row align-center justify-center">
          <v-progress-circular indeterminate color="info"/>
        </span>
      </v-col>
    </v-row>

    <v-dialog v-model="addControllerDialog.isOpen" width="600">
      <v-card>
        <v-card-title>Add New Report {{addControllerDialog.isDuplicate?`As Duplicate from ${(selectedController.metadata.label||'Unnamed')+' | '+selectedController.name}`:''}}</v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name/Label" v-model="addControllerDialog.data.name"/>
          <span class="d-flex flex-row">
            <v-autocomplete :items="controllers" item-value="name" item-text="name" outlined label="Group" v-model="addControllerDialog.data.group"/>
            <v-btn fab color="info" class="mt-2 ml-1" @click="openAddGroupDialog" small><v-icon>mdi-plus</v-icon></v-btn>
          </span>
          <v-text-field outlined label="Route" v-model="addControllerDialog.data.route"/>
          <v-select outlined label="Route Type" :items="routeTypes" v-model="addControllerDialog.data.routeType"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddControllerDialog" color="error">Cancel</v-btn>
          <v-btn @click="addController" :disabled="!validControllerValues(addControllerDialog.data)" :loading="addControllerDialog.loading" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addGroupDialog.isOpen" width="650">
      <v-card>
        <v-card-title>Add New Group</v-card-title>
        <v-card-text>
          <v-text-field outlined label="Name" v-model="addGroupDialog.data.name"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddGroupDialog" color="error">Cancel</v-btn>
          <v-btn @click="addGroup" :disabled="!addGroupDialog.data.name" :loading="addGroupDialog.loading" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="logWindow.isOpen" width="90%">
      <v-card style="overflow-y:hidden;">
        <v-card-title class="d-flex flex-row justify-space-between">
          <span>
            <span>Custom Files</span>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleLogFile">Toggle Logs File</v-btn>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleControllerFile">Toggle Controllers File</v-btn>
            <v-btn small color="teal lighten-3" class="ml-2" @click="toggleRouteFile">Toggle Routes File</v-btn>
          </span>
          <span><v-btn fab small text @click="closeLogWindow"><v-icon>mdi-close</v-icon></v-btn></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="logWindow.log.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Log File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getLog" :loading="logWindow.log.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.log.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/plain', lineWrapping: true, theme: 'dracula', viewportMargin: '10', readOnly: true}" v-model="logWindow.log.data"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="logWindow.controllers.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Controllers File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getControllerFile" :loading="logWindow.controllers.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.controllers.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/javascript', lineWrapping: true, viewportMargin: '10', readOnly: true}" v-model="logWindow.controllers.data"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="logWindow.routes.visible">
              <v-card outlined height="80vh">
                <v-card-title>
                  <span>Routes File</span>
                  <v-btn color="info" x-small fab class="ml-2" @click="getRouteFile" :loading="logWindow.routes.loading"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-progress-circular v-if="logWindow.routes.loading" indeterminate color="info"/>
                  <codemirror v-else class="CodeMirrorScrollable" :options="{...cmOptions, mode: 'text/javascript', lineWrapping: true, viewportMargin: '10', readOnly: true}" v-model="logWindow.routes.data"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAttributeDialog.isOpen" width="650">
      <v-card>
        <v-card-title>Add New Attribute</v-card-title>
        <v-card-text>
          <v-text-field outlined label="Attribute" v-model="addAttributeDialog.data.name"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddAttributeDialog" color="error">Cancel</v-btn>
          <v-btn @click="addAttribute" :disabled="!addAttributeDialog.data.name" color="success">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inputTypeDialog.isOpen" width="800">
      <v-card>
        <v-card-title>Input Types</v-card-title>
        <v-card-text class="d-flex flex-wrap">
          <v-btn @click="setInputType('v-text-field')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VTextField / v-text-field</v-btn>
          <v-btn @click="setInputType('v-checkbox')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VCheckbox / v-checkbox</v-btn>
          <v-btn @click="setInputType('v-textarea')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VTextarea / v-textarea</v-btn>
          <v-btn @click="setInputType('v-btn')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VBtn / v-btn</v-btn>
          <v-btn @click="setInputType('v-autocomplete')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VAutocomplete / v-autocomplete</v-btn>
          <v-btn @click="setInputType('date')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">Date Picker - v-text-field</v-btn>
          <v-btn @click="setInputType('v-combobox')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VCombobox / v-combobox</v-btn>
          <v-btn @click="setInputType('v-file-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VFileInput / v-file-input</v-btn>
          <v-btn @click="setInputType('v-date-picker')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VDatePicker / v-date-picker</v-btn>
          <v-btn @click="setInputType('v-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VInput / v-input</v-btn>
          <v-btn @click="setInputType('v-otp-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VOtpInput / v-otp-input</v-btn>
          <v-btn @click="setInputType('v-overflow-btn')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VOverflowBtn / v-overflow-btn</v-btn>
          <v-btn @click="setInputType('v-radio')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VRadio / v-radio</v-btn>
          <v-btn @click="setInputType('v-slider')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSlider / v-slider</v-btn>
          <v-btn @click="setInputType('v-range-slider')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VRangeSlider / v-range-slider</v-btn>
          <v-btn @click="setInputType('v-select')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSelect / v-select</v-btn>
          <v-btn @click="setInputType('v-switch')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSwitch / v-switch</v-btn>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeInputTypeDialog" color="error">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filtersControl.isOpen" fullscreen>
      <v-card>
        <v-card-title>Filters Preview</v-card-title>
        <v-card-text>
          <div v-if="selectedController">
            <span v-bind="parseAttributes(row.attributes)" v-for="(row, r) of selectedController.metadata?.allFilters" :key="r">
              <template v-for="(filter, f) of row.filters">
                <v-menu
                    :key="f"
                    v-if="filter.inputType==='date'"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :label="filter.label"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="parseAttributes(filter.attributes)"
                        hide-details
                        v-on="on"
                    />
                  </template>
                  <v-date-picker/>
                </v-menu>
                <component v-else :is="filter.inputType" @click="filter.attributes?.content?handleEval(filter.attributes.content.mapper):null"  :items="filter.attributes?.items?parsedDataAttributes[filter.attributes.items.value]:null" v-bind="parseAttributes(filter.attributes)" :label="filter.label" :key="f"><span v-html="filter.attributes.content?.value"></span></component>
              </template>
            </span>
          </div>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="filtersControl.isOpen=false" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.CodeMirrorHeightAuto *{
  height: auto;
}

.CodeMirrorScrollable .CodeMirror{
  height: 75vh;
}

.CodeMirrorScrollable .CodeMirror-vscrollbar{
  overflow-y: scroll;
}
</style>

<script>

import { mapGetters } from 'vuex'
import axios from 'axios';
import draggable from "vuedraggable";
import { VBtn, VTextField, VCheckbox, VTextarea, VAutocomplete, VCombobox, VFileInput, VDatePicker, VInput, VOtpInput, VOverflowBtn, VRadio, VSlider, VRangeSlider, VSelect, VSwitch } from 'vuetify/lib';
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/3024-day.css'
import 'codemirror/theme/3024-night.css'
import 'codemirror/theme/abbott.css'
import 'codemirror/theme/abcdef.css'
import 'codemirror/theme/ambiance-mobile.css'
import 'codemirror/theme/ambiance.css'
import 'codemirror/theme/ayu-dark.css'
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/theme/bespin.css'
import 'codemirror/theme/blackboard.css'
import 'codemirror/theme/cobalt.css'
import 'codemirror/theme/colorforth.css'
import 'codemirror/theme/darcula.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/theme/duotone-dark.css'
import 'codemirror/theme/duotone-light.css'
import 'codemirror/theme/eclipse.css'
import 'codemirror/theme/elegant.css'
import 'codemirror/theme/erlang-dark.css'
import 'codemirror/theme/gruvbox-dark.css'
import 'codemirror/theme/hopscotch.css'
import 'codemirror/theme/icecoder.css'
import 'codemirror/theme/idea.css'
import 'codemirror/theme/isotope.css'
import 'codemirror/theme/juejin.css'
import 'codemirror/theme/lesser-dark.css'
import 'codemirror/theme/liquibyte.css'
import 'codemirror/theme/lucario.css'
import 'codemirror/theme/material-darker.css'
import 'codemirror/theme/material-ocean.css'
import 'codemirror/theme/material-palenight.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/mbo.css'
import 'codemirror/theme/mdn-like.css'
import 'codemirror/theme/midnight.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/moxer.css'
import 'codemirror/theme/neat.css'
import 'codemirror/theme/neo.css'
import 'codemirror/theme/night.css'
import 'codemirror/theme/nord.css'
import 'codemirror/theme/oceanic-next.css'
import 'codemirror/theme/panda-syntax.css'
import 'codemirror/theme/paraiso-dark.css'
import 'codemirror/theme/paraiso-light.css'
import 'codemirror/theme/pastel-on-dark.css'
import 'codemirror/theme/railscasts.css'
import 'codemirror/theme/rubyblue.css'
import 'codemirror/theme/seti.css'
import 'codemirror/theme/shadowfox.css'
import 'codemirror/theme/solarized.css'
import 'codemirror/theme/ssms.css'
import 'codemirror/theme/the-matrix.css'
import 'codemirror/theme/tomorrow-night-bright.css'
import 'codemirror/theme/tomorrow-night-eighties.css'
import 'codemirror/theme/ttcn.css'
import 'codemirror/theme/twilight.css'
import 'codemirror/theme/vibrant-ink.css'
import 'codemirror/theme/xq-dark.css'
import 'codemirror/theme/xq-light.css'
import 'codemirror/theme/yeti.css'
import 'codemirror/theme/yonce.css'
import 'codemirror/theme/zenburn.css'

export default {
  name: 'Home',
  components: {
    draggable,
    VBtn, VTextField, VCheckbox, VTextarea, VAutocomplete, VCombobox, VFileInput, VDatePicker, VInput, VOtpInput, VOverflowBtn, VRadio, VSlider, VRangeSlider, VSelect, VSwitch
  },
  inheritAttrs: false,
  data () {
    return {
      initialLoader: true,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      addControllerDialog: {
        isOpen: false,
        loading: false,
        data: {
          name: "",
          group: "",
          route: "",
          routeType: "post"
        },
        isDuplicate: false
      },

      addGroupDialog: {
        isOpen: false,
        loading: false,
        data: {
          name: ""
        }
      },

      addAttributeDialog: {
        isOpen: false,
        filter: null,
        data: {
          name: ""
        }
      },

      inputTypeDialog: {
        isOpen: false,
        filter: null
      },

      logWindow: {
        isOpen: false,
        log: {
          visible: true,
          data: "",
          loading: false
        },
        controllers: {
          visible: false,
          data: "",
          loading: false
        },
        routes: {
          visible: false,
          data: "",
          loading: false
        }
      },

      controllers: [],
      selectedController: null,

      filteredControllers: [],
      searchControllers: "",

      saveLoading: false,
      testLoading: false,
      deleteLoading: false,
      orderLoading: false,

      importsGlobal: {
        show: false,
        loading: false,
        data: ""
      },

      filtersControl: {
        show: true,
        isOpen: false
      },

      themes: ["3024-day","3024-night","abbott","abcdef","ambiance-mobile","ambiance","ayu-dark","ayu-mirage","base16-dark","base16-light","bespin","blackboard","cobalt","colorforth","darcula","dracula","duotone-dark","duotone-light","eclipse","elegant","erlang-dark","gruvbox-dark","hopscotch","icecoder","idea","isotope","juejin","lesser-dark","liquibyte","lucario","material-darker","material-ocean","material-palenight","material","mbo","mdn-like","midnight","monokai","moxer","neat","neo","night","nord","oceanic-next","panda-syntax","paraiso-dark","paraiso-light","pastel-on-dark","railscasts","rubyblue","seti","shadowfox","solarized","ssms","the-matrix","tomorrow-night-bright","tomorrow-night-eighties","ttcn","twilight","vibrant-ink","xq-dark","xq-light","yeti","yonce","zenburn"],

      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true
      },
      groupDrag: true,
      drag: true,

      routeTypes: ["get", "post", "put", "delete"],

      restrictedAttributes: ['label', 'v-model'],

      parsedDataAttributes: {},

      userTypes: []
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint', 'getUser']),
    dragOptions() {
      return {
        animation: 200
      };
    },
    selectedControllerPrefix(){
      return `exports.${this.selectedController.name} = async (req, res, next) => {`
    },
    selectedControllerSuffix(){
      return `}`
    }
  },
  async mounted(){
    try {
      await this.getAllData()
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    validControllerValues(obj){
      if(!obj) return false;
      if(!obj.name) return false;
      if(!obj.route) return false;
      if(!obj.routeType) return false;

      if(obj.route.includes(" ")) return false;

      return true;
    },
    async getAllData(){
      try{
        this.initialLoader = true;
        let res = await axios.get(`${this.getEndpoint}/api/reportingManager/groupByGroup`)
        if(res.data.error) throw res.data.error
        this.controllers = res.data.data
        this.filteredControllers = this.controllers;

        res = await axios.get(`${this.getEndpoint}/api/globals/customControllerImports`)
        if(res.data.error) throw res.data.error
        this.importsGlobal.data = res.data.data?.value||""

        res = await axios.get(`${this.getEndpoint}/api/usertypes`)
        if(res.data.error) throw res.data.error
        this.userTypes = res.data.data||[]

        this.generateOrder()
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.initialLoader = false;
      }
    },
    openAddControllerDialog(){
      this.addControllerDialog.isOpen = true;
    },
    closeAddControllerDialog(){
      this.addControllerDialog.data = {
        name: "",
        group: "",
        route: "",
        routeType: "post"
      };
      this.addControllerDialog.isOpen = false;
      this.addControllerDialog.isDuplicate = false;
    },
    filterControllers(){
      if(!this.searchControllers || !(this.searchControllers.trim())){
        this.filteredControllers = this.controllers;
        return;
      }

      this.filteredControllers = this.controllers.filter(x => x.metadata?.label?.toLowerCase().includes(this.searchControllers.toLowerCase()) || x.route.toLowerCase().includes(this.searchControllers.toLowerCase()))
    },
    async addController(){
      try{
        if(this.addControllerDialog.isDuplicate && !this.selectedController){
          this.closeAddControllerDialog();
          throw "No controller is selected to duplicate."
        }

        let obj = {
          ...this.addControllerDialog.data, id: null
        }

        if(this.addControllerDialog.isDuplicate){
          obj = {
            ...this.selectedController,
            ...obj,

          }
          delete obj.createdAt
          delete obj.updatedAt
        }

        this.addControllerDialog.loading = true;

        let res = await axios.post(`${this.getEndpoint}/api/reportingManager/`, obj)
        if(res.data.error) throw res.data.error

        this.closeAddControllerDialog();

        await this.getAllData()

        this.selectedController = res.data.data

        this.snack("Controller created", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.addControllerDialog.loading = false;
      }
    },
    async duplicateController(){
      try{
        this.addControllerDialog.isDuplicate = true;
        this.openAddControllerDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.addControllerDialog.loading = false;
      }
    },
    async saveController(){
      try{
        this.saveLoading = true;

        let res = await axios.put(`${this.getEndpoint}/api/reportingManager/${this.selectedController.id}`, this.selectedController)
        if(res.data.error) throw res.data.error

        this.selectedController = res.data.data;

        await this.getAllData()

        this.snack("Controller updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.saveLoading = false;
      }
    },
    async deleteController(){
      try{
        this.deleteLoading = true;

        let verified = confirm("Are you sure you want to delete this report?");
        if(!verified) return;

        let res = await axios.delete(`${this.getEndpoint}/api/reportingManager/${this.selectedController.id}`)
        if(res.data.error) throw res.data.error

        this.selectedController = null
        await this.getAllData()

        this.snack("Controller deleted", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.deleteLoading = false;
      }
    },
    async saveControllerOrder(){
      try{
        this.orderLoading = true;

        let cleansed = this.controllers.map(g => {
          return {
            ...g,
            controllers: g.controllers.map(c => {
              return {
                id: c.id,
                group: c.group,
                order: c.order,
                groupOrder: c.groupOrder
              }
            })
          }
        });

        let res = await axios.put(`${this.getEndpoint}/api/reportingManager/saveOrder`, cleansed)
        if(res.data.error) throw res.data.error

        await this.getAllData()

        this.snack("Order updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.orderLoading = false;
      }
    },
    openAddGroupDialog(){
      this.addGroupDialog.isOpen = true;
    },
    closeAddGroupDialog(){
      this.addGroupDialog.data = {name: ""};
      this.addGroupDialog.isOpen = false;
    },
    addGroup(){
      this.controllers.push({name: this.addGroupDialog.data.name, controllers: []})

      this.closeAddGroupDialog();
    },
    groupOrderChanged(){
      this.groupDrag=false;

      this.generateOrder();
    },
    orderChanged(){
      this.drag=false;

      this.generateOrder();
    },
    generateOrder(){
      let count = 0;
      for(let i=0;i<this.controllers.length;i++){
        this.controllers[i].groupOrder = i;

        for(let controller of this.controllers[i].controllers){
          controller.order = count;
          controller.group = this.controllers[i].name||""
          controller.groupOrder = i;
          count++;
        }
      }
    },
    async loadController(id){
      try{
        this.selectedController = {
          contents: ""
        };

        let res = await axios.get(`${this.getEndpoint}/api/reportingManager/${id}`)
        if(res.data.error) throw res.data.error

        let con = res.data.data;

        if(!con.metadata){
          con.metadata = {};
        }
        if(!con.metadata.allFilters){
          con.metadata.allFilters = []
        }

        for(let row of con.metadata.allFilters){
          for(let filter of row.filters){
            await this.loadParsedAttributes(filter.attributes, true);
          }
        }

        this.selectedController = con;

        let found;
        for(let g of this.controllers){
          let quickExit = false;
          for(let c of g.controllers){
            if(c.id===id){
              found = c;
              quickExit = true;
              break;
            }
            if(quickExit) break;
          }
        }
        if(found) this.selectedController.group = found.group;

        this.snack("Controller loaded")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async saveImports(){
      try{
        this.importsGlobal.loading = true;
        let res = await axios.put(`${this.getEndpoint}/api/globals/customControllerImports`, {value: this.importsGlobal.data})
        if(res.data.error) throw res.data.error

        await this.getAllData()

        this.snack("Imports updated", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.importsGlobal.loading = false;
      }
    },
    async testController(){
      try{
        this.testLoading = true
        let res;
        if(this.selectedController.routeType==='get' || this.selectedController.routeType==='delete')
          res = await axios[this.selectedController.routeType](`${this.getEndpoint}/api/custom/${this.selectedController.route}`)
        else
          res = await axios[this.selectedController.routeType](`${this.getEndpoint}/api/custom/${this.selectedController.route}`, {report: this.selectedController.name})
        if(res.data.error) throw res.data.error
        console.log(`START OF Custom Test [${this.selectedController.name}] Response: `)
        console.log(res.data)
        console.log(`END OF Custom Test [${this.selectedController.name}] Response`)

        this.snack("Controller tested. See Console Log", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.testLoading = false
      }
    },
    async reloadRoutes(){
      try{
        this.testLoading = true
        let res = await axios.put(`${this.getEndpoint}/api/reportingManager/reloadRoutes`)
        if(res.data.error) throw res.data.error
        console.log("Reloading Routes", res.data)

        this.snack("Routes reloading. Check log to validate.", "warning")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.testLoading = false
      }
    },
    openLogWindow(){
      this.logWindow.isOpen = true;

      if(this.logWindow.log.visible) this.getLog();
      if(this.logWindow.controllers.visible) this.getControllerFile();
      if(this.logWindow.routes.visible) this.getRouteFile();
    },
    closeLogWindow(){
      this.logWindow.isOpen = false;
    },
    toggleLogFile(){
      this.logWindow.log.visible=!this.logWindow.log.visible
      if(this.logWindow.log.visible){
        this.getLog()
      }
    },
    async getLog(){
      try{
        this.logWindow.log.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/reportingManager/getLogFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.log.data = res.data.data;

        this.snack("Log Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.log.loading = false
      }
    },
    toggleControllerFile(){
      this.logWindow.controllers.visible=!this.logWindow.controllers.visible
      if(this.logWindow.controllers.visible){
        this.getControllerFile()
      }
    },
    async getControllerFile(){
      try{
        this.logWindow.controllers.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/reportingManager/getControllerFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.controllers.data = res.data.data;

        this.snack("Controllers Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.controllers.loading = false
      }
    },
    toggleRouteFile(){
      this.logWindow.routes.visible=!this.logWindow.routes.visible
      if(this.logWindow.routes.visible){
        this.getRouteFile()
      }
    },
    async getRouteFile(){
      try{
        this.logWindow.routes.loading = true
        let res = await axios.get(`${this.getEndpoint}/api/reportingManager/getRouteFile`)
        if(res.data.error) throw res.data.error
        this.logWindow.routes.data = res.data.data;

        this.snack("Routes Loaded.", "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }finally {
        this.logWindow.routes.loading = false
      }
    },
    deleteRow(i){
      if(!this.selectedController.metadata?.allFilters) throw "No rows in filters."

      this.selectedController.metadata.allFilters.splice(i, 1);
    },
    addFilter(row){
      if(!row) throw "No row to push filter to."

      let obj = {
        attributes: {
          class: {value: '', route: ''},
          style: {value: '', route: ''},
          outlined: {value: '', route: ''},
          dense: {value: '', route: ''},
          "hide-details": {value: '', route: ''},
          content: {value: 'My Content', route: ''},
        }
      }

      row.filters.push(obj);
    },
    deleteFilter(row, i){
      if(!row) throw "No row to remove filter from."

      row.filters.splice(i, 1);
    },
    openAddAttributeDialog(filter){
      this.addAttributeDialog.isOpen = true;
      this.addAttributeDialog.filter = filter;
    },
    closeAddAttributeDialog(){
      this.addAttributeDialog.isOpen = false;
      this.addAttributeDialog.data = {name: ""};
      this.addAttributeDialog.filter = null;
    },
    addAttribute(){
      if(!this.addAttributeDialog.filter) throw "No filter to add attribute to."
      if(!this.addAttributeDialog.data.name?.trim()) throw "No name for attribute"
      if(!this.addAttributeDialog.filter.attributes){
        this.addAttributeDialog.filter.attributes = {};
      }

      if(Object.hasOwn(this.addAttributeDialog.filter.attributes, this.addAttributeDialog.data.name.toLowerCase())) throw "This attribute already exists for this filter."

      if(this.restrictedAttributes.includes(this.addAttributeDialog.data.name.toLowerCase())) throw "This attribute is not allowed. "

      this.addAttributeDialog.filter.attributes[this.addAttributeDialog.data.name.toLowerCase()] = {value: '', route: ''};

      this.closeAddAttributeDialog();

      this.snack('Attribute added')
    },
    deleteAttribute(filter, attr){
      delete filter.attributes[attr];
      this.$forceUpdate();
    },
    setSampleFilters(){
      let obj = [
        {
          "filters": [
            {
              "label": "Show Zero",
              "inputType": "v-checkbox",
              "attributes": {
                "class": {
                  "route": "",
                  "value": "pl-5"
                },
                "style": {
                  "route": "",
                  "value": ""
                }
              },
              "filtersCustomKey": "showZero"
            },
            {
              "label": "DOB",
              "inputType": "date",
              "attributes": {
                "class": {
                  "route": "",
                  "value": "ml-5"
                },
                "style": {
                  "route": "",
                  "value": "border: 2px solid red;"
                }
              },
              "filtersCustomKey": "dob"
            }
          ],
          "attributes": {
            "class": {
              "route": "",
              "value": "d-flex flex-row mt-2"
            },
            "style": {
              "route": "",
              "value": ""
            }
          }
        },
        {
          "filters": [
            {
              "label": "Suppliers",
              "inputType": "v-autocomplete",
              "attributes": {
                "dense": {
                  "route": "",
                  "value": ""
                },
                "items": {
                  "route": "`${this.getEndpoint}/api/suppliers`",
                  "value": "suppliers",
                  "mapper": "data.map((x, i) => {\n  return {\n    text: x.name,\n    value: x.id,\n    item: x,\n    index: i\n  }\n}) ",
                  "itemText": "`${x.name||''} ${x.id>5?'(Deleted)':''}`",
                  "itemValue": "x.id"
                },
                "multiple": {
                  "route": "",
                  "value": ""
                },
                "outlined": {
                  "route": "",
                  "value": ""
                }
              },
              "filtersCustomKey": "suppliers"
            },
            {
              "label": "More Users",
              "inputType": "v-select",
              "attributes": {
                "class": {
                  "route": "",
                  "value": ""
                },
                "dense": {
                  "route": "",
                  "value": ""
                },
                "items": {
                  "route": "`${this.getEndpoint}/api/users/full`",
                  "value": "users",
                  "mapper": "data.map((x, i) => {\n  return {\n    text: `${x.firstName||''} ${x.lastName||''} ${x.deletedAt?'(Deleted)':''}`, \n    value: x.id, \n    item: x, \n    index: i\n  }\n}) ",
                  "itemText": "`${x.firstName||''} ${x.lastName||''} ${x.id>5?'(Deleted)':''}`",
                  "itemValue": "x.id"
                },
                "style": {
                  "route": "",
                  "value": ""
                },
                "outlined": {
                  "route": "",
                  "value": ""
                },
                "hide-details": {
                  "route": "",
                  "value": ""
                }
              }
            },
            {
              "inputType": "v-btn",
              "attributes": {
                "class": {
                  "route": "",
                  "value": ""
                },
                "dense": {
                  "route": "",
                  "value": ""
                },
                "style": {
                  "route": "",
                  "value": ""
                },
                "content": {
                  "route": "",
                  "value": "My Content",
                  "mapper": "this.filters.suppliers = (this.parsedDataAttributes.suppliers||[]).map(y => y.id);"
                },
                "outlined": {
                  "route": "",
                  "value": ""
                },
                "hide-details": {
                  "route": "",
                  "value": ""
                }
              }
            }
          ],
          "attributes": {
            "class": {
              "route": "",
              "value": ""
            },
            "style": {
              "route": "",
              "value": ""
            }
          }
        }
      ]

      this.selectedController.metadata.allFilters = obj;
    },
    closeInputTypeDialog(){
      this.inputTypeDialog.isOpen = false;
      this.inputTypeDialog.filter = null;
    },
    openInputTypeDialog(filter){
      this.inputTypeDialog.isOpen = true;
      this.inputTypeDialog.filter = filter;
    },
    setInputType(type){
      this.inputTypeDialog.filter.inputType = type;
      this.inputTypeDialog.isOpen = false;
    },
    parseAttributes(attrs){
      let obj = {}

      for(let key of Object.keys(attrs)){
        if(['item-text', 'item-value'].includes(key)){
          obj[key] = eval(attrs[key].value)
          continue;
        }
        else if(['items', 'content'].includes(key)){
          continue;
        }

        obj[key] = attrs[key].value
      }

      return {...obj, ...this.$attrs}
    },
    addRow(){
      if(!this.selectedController) throw "No selected controller";

      let obj = {
        attributes: {
          class: {value: '', route: ''},
          style: {value: '', route: ''}
        },
        filters: []
      }

      this.selectedController.metadata.allFilters.push(obj);
    },
    async loadParsedAttributes(attrs, force=false){
      try{
        if(attrs.items){
          if(attrs.items.value && (force || !this.parsedDataAttributes[attrs.items.value])){
            if(attrs.items.value && attrs.items.route && attrs.items.mapper){
              let res = await axios.get(eval(attrs.items.route))
              if(res.data.error) throw res.data.error

              let data = res.data.data;
              if(this.getUser.userTypeId===1){
                console.log(data)
              }
              let mapped = eval(attrs.items.mapper)

              this.parsedDataAttributes[attrs.items.value] = mapped;
            }
          }
        }
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    refreshPreview(filter){
      this.loadParsedAttributes(filter.attributes, true);
      this.$forceUpdate();
    },
    handleEval(data){
      eval(data);
      this.$forceUpdate();
    },
    moveUp(i, arr){
      if(i<=0) return;

      [arr[i-1], arr[i]] = [arr[i], arr[i-1]];
      this.$forceUpdate();
    },
    moveDown(i, arr){
      if(i>=arr.length-1) return;

      [arr[i+1], arr[i]] = [arr[i], arr[i+1]];
      this.$forceUpdate();
    },
  }
}
</script>
